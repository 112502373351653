<template>
  <div>
    <section class="bg-half bg-soft-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h1 class="title mb-0">Cadastro de Cidadão</h1>
              <h5 class="text-muted">Crie sua conta grátis.</h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>

    <section class="section pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
            <div class="card custom-form border-0">
              <div class="card-body">
                <form class="rounded shadow p-4">
                  <div class="row mb-2 px-2 border-bottom text-primary font-weight-bold">
                    Dados do Usuário
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>CPF <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-form-input
                              v-model="form.document"
                              :state="$v.form.document.$error ? false : null"
                              @blur="$v.form.document.$touch"
                              v-mask="'###.###.###-##'"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.document) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Nome <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.name"
                              :state="$v.form.name.$error ? false : null"
                              @blur="$v.form.name.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.name) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>WhatsApp <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.phonenumber"
                              :state="$v.form.phonenumber.$error ? false : null"
                              @blur="$v.form.phonenumber.$touch"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.phonenumber) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="form-group">
                        <label>E-mail <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.email"
                              :state="$v.form.email.$error ? false : null"
                              @blur="$v.form.email.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.email) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Senha <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              type="password"
                              v-model="form.password"
                              :state="$v.form.password.$error ? false : null"
                              @blur="$v.form.password.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.password) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Confirmação da Senha <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              type="password"
                              v-model="form.confirm_password"
                              :state="$v.form.confirm_password.$error ? false : null"
                              @blur="$v.form.confirm_password.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.confirm_password) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">

                      <div class="form-group">
                        <b-form-checkbox
                            v-model="check_terms"
                            :state="$v.check_terms.$error ? false : null"
                            @blur="$v.check_terms.$touch"
                        >
                          <template #default>
                            Declaro que li e concordo com os
                            <router-link
                                :to="{ name: 'termos-de-servico' }"
                                class="text-primary text-hover-primary"
                                target="_blank"
                            >
                              <span class="text-primary">Termos de Serviço</span>
                            </router-link>
                            e
                            <router-link
                                :to="{ name: 'politica-de-privacidade' }"
                                class="text-primary text-hover-primary"
                                target="_blank"
                            >
                              <span class="text-primary">Política de Privacidade</span>.
                            </router-link>
                          </template>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <b-button
                          :disabled="loading"
                          class="btn-primary w-100"
                          @click="submit"
                      >
                        Cadastrar
                      </b-button>
                    </div>
                    <div class="mt-2 small text-muted col-sm-12">
                      Este site é protegido por reCAPTCHA e a
                      <a href="https://policies.google.com/privacy" target="_blank">Política de Privacidade</a> e os
                      <a href="https://policies.google.com/terms" target="_blank">Termos de Serviço</a> do Google se aplicam.
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BiddingSystemPreRegistrationService from "@/services/BiddingSystemPreRegistrationService";
import messageAlert from "@/utils/messageAlert";
import errorDefaultAxios from "@/utils/errorDefaultAxios";
import messagesValidation from "@/utils/messagesValidation";
import {maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMessage} from "vuelidate-messages"
import {hasLowercase, hasNumber, hasSpecial, hasUppercase} from "@/core/functions";

export default {
  components: {},
  data() {
    return {
      loading: false,
      form: {
        name: null,
        document: null,
        email: null,
        phonenumber: null,
        password: null,
        confirm_password: null,
      },
      check_terms: false,
    }
  },
  validations: {
    form: {
      document: {
        required,
        maxLength: maxLength(14),
        minLength: minLength(14),
      },
      name: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(255),
      },
      email: {
        required,
        maxLength: maxLength(255),
      },
      phonenumber: {
        required,
        maxLength: maxLength(15),
      },
      password: {
        required,
        minLength: minLength(8),
        hasNumber: hasNumber,
        hasLowercase: hasLowercase,
        hasUppercase: hasUppercase,
        hasSpecial: hasSpecial,
      },
      confirm_password: {
        required,
        sameAs: sameAs('password'),
      }
    },
    check_terms: {
      check_terms: sameAs(() => true),
    },
  },
  created() {
  },
  computed: {},
  methods: {
    validationMsg: validationMessage(messagesValidation),

    async submit() {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if (this.$v.check_terms.$invalid) {
            messageAlert('É necessário aceitar os termos para continuar o cadastro.', 'error')
            return
          }
          this.loading = true
          const payload = this.form
          payload['origin'] = window.location.host

          payload['g-recaptcha-response'] = await this.$recaptcha('form_user_register')
          const response = await BiddingSystemPreRegistrationService.userCreate(payload)
          const {message} = response.data

          messageAlert(message)
          await this.$router.push({name: 'home'})
        } else {
          messageAlert('Existem campos inválidos no formulário!', 'error')
        }
      } catch (error) {
        errorDefaultAxios(error)
      } finally {
        this.loading = false
      }
    }
  },
};
</script>


